import React from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import EmailIcon from '@material-ui/icons/Email';
import LinearProgress from '@material-ui/core/LinearProgress';

//================Methods=============
import getCounsellingControl from "../methods/getCounsellingControl";
import handleOpenPref from "../methods/handleOpenPref";
import handleClosePref from "../methods/handleClosePref";
import handleAnnounce from "../methods/handleAnnounce";
//import handleAnnounce from "../methods/handleAnnounce";
import handleReset from "../methods/handleReset";

import EmailCounsellingSchedule from '../methods/EmailCounsellingSchedule'

import {ProtectTimestamp} from '../components/ProtectTimestamp';






export default class CounsellingControl extends React.Component {

    state={
       
       programentrance_id:0,
       message:'',
       controlData:[],
       disableOpenCount:0,
       disableCloseCount:0,
       disableAnnounceCount:0,
       disableResetCount:0,
       setProgress:false,
       time:undefined,
       disableEmailCount:0

    
    }

    componentDidMount=()=>{
// this.setState({
//     message:'hi'
// })
    this.getCounsellingControl();
    }


    getCounsellingControl=()=>{
        getCounsellingControl().then(resp=>{
            if(resp){
            console.log(resp.data);
              this.setState({
                controlData:resp.data
              })
            }else{
              this.setState({
                message:'No Data Found'
            })
            }
        })
    }

    HandleOpenPref=(i,e)=>{
//console.log(e)
this.setState({
  setProgress:true
})
console.log(i);
handleOpenPref(i).then(
  resp=>{
    if(resp){ 
      if(resp.data==='Yes'){
      
      this.setState({
       // time:resp.data,
       disableOpenCount:i,
        message:'Open Course Prefrence Action is Successfully Set!',
        setProgress:false
      })
      console.log('hi react')
    }else{
      this.setState({

         message:'Something Happened!'
       })
    }
    }}

);
}

    HandleClosePref=(i,e)=>{
      console.log(i);
      this.setState({
        setProgress:true
      })
      handleClosePref(i).then(
        resp=>{
          if(resp){ 
            if(resp.data==='Yes'){
            
            this.setState({
             // time:resp.data,
             disableCloseCount:i,
              message:'Close Course Prefrence Action is Successfully Set!',
              setProgress:false
            })
            console.log('hi react')
          }else{
            this.setState({
      
               message:'Something Happened!'
             })
          }
          }})
      
          }

          HandleAnnounce=(i,e)=>{
            console.log(i);
            this.setState({
              setProgress:true
            })
            handleAnnounce(i).then(
              resp=>{
                if(resp){ 
                  if(resp.data==='Yes'){
                  
                  this.setState({
                   // time:resp.data,
                   disableAnnounceCount:i,
                    message:'Result Announcement Action is Successfully Set!',
                    setProgress:false
                  })
                  console.log('hi react')
                }else{
                  this.setState({
            
                     message:'Something Happened!'
                   })
                }
                }})            
                }

                HandleReset=(i,e)=>{
                  console.log(i);
                  this.setState({
                    setProgress:true
                  })
                  handleReset(i).then(
                    resp=>{
                      if(resp){ 
                        if(resp.data==='Yes'){                        
                        this.setState({
                         // time:resp.data,
                         disableResetCount:i,
                          message:'Reset Action is Successfully Set!',
                          setProgress:false
                        })
                        console.log('hi react')
                      }else{
                        this.setState({
                  
                           message:'Something Happened!'
                         })
                      }
                      }})
                  
                      }

                      emailCounsellingSchedule=(i,e)=>{
                        //console.log('hello Email');
                        //console.log(i);
                        this.setState({
                          setProgress:true,
                         
                          
                        })
                        EmailCounsellingSchedule(i).then(resp=>{ 
                          if(resp){     
                           if(resp.data==='fail'){
                                  this.setState({
                                    message:'Unable to update.Try Again',
                                    disableEmailCount:i,
                                   
                                    setProgress:false,
                                  })
                            }
                            else if(resp.data==='Check Internet Connection'){
                              this.setState({
                                message:'Check Internet Connection',
                                disableEmailCount:i,
                                setProgress:false,
                              })
                      
                            }
                            else if(resp.data==='No'){
                                this.setState({
                                  message:'Email Id is missing',
                                  disableEmailCount:i,
                                  setProgress:false,
                                })
                            }
                            else{
                              this.setState({
                                message:'Mail Send Succesfully',
                                disableEmailCount:i,
                               // disableflag:1,
                                setProgress:false,
                              })
                            }
                          }
                        }
                        )
                        //seatValueArray:{...this.state.seatValueArray,
                          // [i]:e.target.value}
                      }
                      

    render(){
       
            return( 
                <div> 
                  <Grid style={{paddingLeft:'0%',paddingRight:'0%'}} >
    
                  <Card>
                  <Box border={2} style={{color:'#336699'}}>
                <Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
                      <Typography variant='h4' style={{textAlign:'center'}}>
                      Counselling Control Actions
                         
                      </Typography>
                    </Box> 
    
           
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}
    
                    {this.state.message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                    {this.state.setProgress===true?(
<LinearProgress color="secondary"
                           disabled={true}
                            />
                            ):(<div></div>)}
                     <Grid style={{padding:'1%',width:'100%'}}>  {/* Start Left Side Grid */}
    
                     <MaterialTable
                    name="Counselling Controls"
                    title="Counselling Control and Actions"
                   
                                columns  ={[
                                  {title:'S.No',field:'sno'},
                                 // {title:'Year',field:'year',name:'year'},
                                  {title:'Admission Type', field:'type',name:'type'},
                                  {title:'Exam Name', field:'testType',name:'testType'},
                                  {title:'Round', field:'round',name:'round'},
                                  {title:'Open Preference',field:'opref',export: false},
                                  {title:'Close Preference',field:'cpref',export: false},
                                  {title:'Result Announcement',field:'result',export: false},
                                  {title:'Reset',field:'reset',export: false},
                                  {title:'Email',field:'email',export: false},
                                  {title:'Email TimeStamp',field:'emailTime',export: false},
                                ]}
                         
                                data={                               
                                    this.state.controlData.map((cData,id)=>(
                                        {   'sno':id+1,                              
                                          // 'year':counsellingDetail.year,
                                          'type':cData.typePro,
                                          'testType':cData.EntranceName,
                                          'round':cData.counsellingCount,

                                          'opref':
                                          cData.openPrefernce?
                                          (ProtectTimestamp(cData.openPrefernce)):
                                          (<Button
                                          disabled={cData.control_id===this.state.disableOpenCount}
                                          color="primary"
                                         // onClick={this.handleOpenPref(cData.typePro)}
                                          onClick={this.HandleOpenPref.bind(this,cData.control_id)}
                                          >
                                          Open
                                          </Button>)
                                          ,

                                          'cpref':
                                          cData.closePrefrence?
                                          (ProtectTimestamp(cData.closePrefrence))://check pre or per
                                          (<Button
                                          disabled={cData.control_id===this.state.disableCloseCount}
                                          color="primary"
                                         // onClick={this.handleOpenPref(cData.typePro)}
                                          onClick={this.HandleClosePref.bind(this,cData.control_id)}

                                          >
                                          Close
                                          </Button>)
                                          ,

                                          'result':
                                          cData.announceResult?
                                          (ProtectTimestamp(cData.announceResult))://check pre or per
                                          (<Button
                                          disabled={cData.control_id===this.state.disableAnnounceCount}
                                          color="primary"
                                         // onClick={this.handleOpenPref(cData.typePro)}
                                          onClick={this.HandleAnnounce.bind(this,cData.control_id)}

                                          >
                                          Announce
                                          </Button>)
                                          ,

                                          'reset':
                                          cData.reset?
                                          (ProtectTimestamp(cData.reset))://check pre or per
                                          (<Button
                                          disabled={cData.control_id===this.state.disableResetCount}
                                          color="primary"
                                         // onClick={this.handleOpenPref(cData.typePro)}
                                          onClick={this.HandleReset.bind(this,cData.control_id)}

                                          >
                                          Reset
                                          </Button>)
                                          ,
                                          'email':
                                          // cData.emailLog?(cData.emailLog):
                                          <Button 
                                          //key={counsellingDetail.counsellingDate_id}
                                          disabled={cData.control_id===this.state.disableEmailCount}
                                         // id={counsellingDetail.counsellingDate_id}
                                        //  value={counsellingDetail.counsellingDate_id}
                                          onClick={this.emailCounsellingSchedule.bind(this,cData.control_id)}                                         
                                          color="primary">
                                          <EmailIcon/>
                                       </Button>,

                                          'emailTime':
                                          cData.emailLog?(ProtectTimestamp(cData.emailLog)):('--')
                                            
                                      
                                    }
                                    ))}
                                    options={{
                                      exportButton: true,
                                      pageSize: 5,
                                      pageSizeOptions: [5, 10, 20,this.state.controlData.length],
                                      toolbar: true,
                                      paging: true
                                    
                                    }} 
                                    />
                             
                        </Grid> 
{/* 
    
                    <Grid style={{padding:'1%',width:'100%'}}>
                        <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                        {messageStdInfo && <Box p={3}><h5 style={{color: 'red', fontSize:'2em'}}><em>{this.state.messageStdInfo} </em></h5></Box>}
             
                  </Paper>
                    </Grid>             */}
                      </Paper>   {/* ENd Main Paper tag */}          
                      </Box>
                      </Card>      
                         </Grid>        
                </div> 
            );
    }


}