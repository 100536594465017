import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import Avatar from '@material-ui/core/Avatar';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InboxIcon from "@material-ui/icons/Inbox";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ItemList from "./ItemList";
import {ProtectRank} from './ProtectRank';
// import GetCategoryRank from '../methods/GetCategoryRank';



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,2,235)" //235,235,235
  })
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
});



export default class DND4category extends React.Component{
    constructor(props) {
    super(props);
      this.state={
        //categoryRank:[],
        appendList:[],
        category:[]
      }
      this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount=()=>{

   this.setState({
     category:this.props.categoryList
   })
   let testCat=this.props.categoryList;

   //var test=this.props.categoryList;
   console.log(this.props.categoryList);
   console.log("Cat Rank");
   console.log(this.props.categoryRank);
    
   
   //let checkCat=this.state.appendList.filter(list=>appendList.category!==catList)
    // var std=this.props.stdId;
    // var test=this.props.entrance;
    // console.log(catList);
    // console.log(std);
    // console.log(test);
   // this.getCategoryRank();

  }
  // getCategoryRank=()=>{
  //   var categoryList=this.props.categoryList;
  //   var stdId=this.props.stdId;
  //   var entranceId=this.props.entrance;
  //   GetCategoryRank(categoryList,stdId,entranceId).then(resp=>{
  //     if(resp){
  //       if(resp.data ){
  //           this.setState({
  //             categoryRank:resp.data
  //           })
  //       }
  //       else{
  //         console.log("No data to Display");
  //       }
  //     }     
  //   })
  // }

   onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const userCategory = reorder(
      this.props.categoryList,
      result.source.index,
      result.destination.index
    );
    this.props.reorderCategoryList(userCategory);
  }

  onMoveDown = (index) => {
    if(this.props.categoryList[index+1]){
      const userCategory = reorder(
        this.props.categoryList,
        index,
        index+1
      );
      this.props.reorderCategoryList(userCategory);
    }
  }
  onMoveUp=(index)=>{
  if(this.props.categoryList[index-1]){
    const userCategory = reorder(
      this.props.categoryList,
      index,
      index-1
    );
    this.props.reorderCategoryList(userCategory);
  }
}


getRankDisplay=(item)=>{
  console.log(item);
  //return item;
  var abc=0;
                         this.props.categoryRank.map((catRank,index)=>{  

                        // var categoryRank1=catRank[0] ;
                          console.log(catRank);       
                       //  return 'hi';                  
                            if(catRank.category===item[0])
                               { abc= catRank.rank ;
                                console.log(abc);
                                abc=index;
                                   
                                                    
                               }
                        })
                        console.log(abc);
                        if(abc===0){
                          return 'Merit list not uploaded ';
                        }
                        else
                        return 'Rank: '+abc;
}


 render() {
  //console.log(this.state.categoryRank)
  console.log(this.state.category)

  




    return (     
<div>

                
   <List>
   {this.props.categoryList.map((item,index) => ( 
     <ListItem  ContainerComponent="li">
              <ListItemIcon>
                <Avatar>{index+1}</Avatar>  
              </ListItemIcon>

              <ListItemText                         
                          primary={item}  
                          secondary={item?(ProtectRank(item,this.props.categoryRank,0)):'--'}
                          //{this.getRankDisplay(item)}
                       
                />

                 <IconButton onClick={()=>this.onMoveUp(index)} 
                            disabled={index === 1|| index === 0}
                            title='Move Up'>
                          <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton onClick={()=>this.onMoveDown(index)}
                           disabled= {index === 0||index === (this.props.categoryList.length-1)}         // {index === (this.props.categoryList.length-1)}  {index === 0}
                            title='Move Down'>
                            <ArrowDownwardIcon />
                          </IconButton>
                        <ListItemSecondaryAction>
                          {/* <IconButton   onClick={this.deleteItem.bind(this,index)}>
                            <DeleteIcon/>
                          </IconButton> */}
                        </ListItemSecondaryAction>



     </ListItem>
    ))}
   </List>





      {/* <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <List style={getListStyle(snapshot.isDraggingOver)}>       
               



                {this.props.categoryList.map((item,index) => (                           
                  <Draggable key={index} draggableId={`item-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <ListItem
                        ContainerComponent="li"
                        ContainerProps={{ ref: provided.innerRef }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                      
                       <ListItemIcon>
                          <Avatar>{index+1}</Avatar>  
                        </ListItemIcon>
                      
                        <ListItemText                         
                          primary={item} 
                         secondary=
                         {this.props.categoryRank.map(catRank=>{                         
                          var categoryRank1=catRank[0]                          
                            if(categoryRank1.category===item)
                               { 
                                 return ('Rank '+categoryRank1.rank)                                
                               }
                        })}
                        />
                           <IconButton onClick={()=>this.onMoveUp(index)} 
                            disabled={index === 0}
                            title='Move Up'>
                          <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton onClick={()=>this.onMoveDown(index)}
                           disabled={index === (this.props.categoryList.length-1)}
                            title='Move Down'>
                            <ArrowDownwardIcon />
                          </IconButton>
                        <ListItemSecondaryAction>
                          <IconButton   onClick={this.deleteItem.bind(this,index)}>
                            <DeleteIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext> */}
                     
            </div>
    );
  }
}
