export const ProtectRank=(item,categoryRank,temp)=>{
    // console.log(item);
    // console.log(categoryRank);
    //var catRank=categoryRank[0];
    var abc=0;  
                categoryRank.map((catRank1,index)=>{
                  //console.log(catRank1)
                  catRank1.map((catRank2,id)=>{
                  
                    if(catRank2.category===item){
                      abc= catRank2.rank;
                    //   console.log(abc);
                    //   console.log("Hello");
                    //   //console.log(catRank2)
                    // console.log(catRank2.category)
                    // console.log(catRank2.rank)
                    }

                    // console.log(catRank2)
                    // console.log(catRank2.category)
                  })
                })


                if(abc===0){
                  return ' ';

                 // return '-- ';
                }
                else
                if(temp===0)
                return '(Rank: '+abc+')';
                else
                return abc;


    //console.log(catRank);
  //return item;
  // var abc=0;
  //                        categoryRank.map((catRank,index)=>{  

  //                       // var categoryRank1=catRank[0] ;
  //                         console.log(catRank.category);       
  //                      //  return 'hi';                  
  //                           if(catRank.category===item)
  //                              { abc= catRank.rank ;
  //                               console.log(abc);
  //                              // abc=index;
                                   
                                                    
  //                              }
  //                       })
  //                       console.log(abc);
  //                       if(abc===0){
  //                         return 'Merit list not uploaded ';
  //                       }
  //                       else
  //                       return 'Rank: '+abc;
}