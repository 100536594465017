import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import PAUCounsellingDetail from '../methods/PAUCounsellingDetail';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import BlinkLable from './BlinkLable';
//import { IconButton} from '@material-ui/core';


const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

export default class PAUScheduleIcon extends React.Component{
    state={
        setOpenHelp:false,
        counsellingDetail:[],
        noScheduleFlag:0
    }
    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false 
          
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true,
          // counsellingDetail:[]

        })
    }
    componentDidMount(){
         this.ViewCounsellingDetail();
    }

    ViewCounsellingDetail=()=>{
      console.log("Hello Counselling View");
      PAUCounsellingDetail().then(resp=>{
          if(resp){
            if(resp.data==='No'){
              this.setState({
                noScheduleFlag:1,
                counsellingDetail:[]
              })
            }
            else{
              this.setState({
                counsellingDetail:resp.data,
                noScheduleFlag:0,
              })
            }
              
          }
      })

    }
    
render(){
    // const classes = useStyles;
//console.log('aaina')
console.log(this.state.counsellingDetail);
    return(
        <div>
            <Button onClick={this.setdialogHelp}>
            <BlinkLable label='Counselling Schedule'> </BlinkLable>  
            </Button>

             {/* <IconButton title='HELP' onClick={this.setdialogHelp} style={{color:'white'}}>
                          <HelpTwoToneIcon fontSize='large'/>
                          </IconButton> */}
                          
              <Dialog 
              //fullScreen
              maxWidth='lg'
              fullWidth={true}
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                         
                         >

<DialogActions>
                           <IconButton onClick={this.handleNoHelp}>
                             <ClearTwoToneIcon />
                           </IconButton>
                        
                           {/* <Button onClick={this.handleNoHelp} color="primary">
                             Close
                           </Button> */}
                         </DialogActions>
                        {/* <Dialog  fullScreen
                            // maxWidth={maxWidth}
                            open={this.state.setOpenHelp}
                            onClose={this.handleNoHelp}
                            aria-labelledby="max-width-dialog-title"
                        > */}
                        
                          <DialogTitle id="alert-dialog-title">{"Counselling Schedule 2020"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                          {this.state.noScheduleFlag==1?(
                             <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                               <h1> Counselling Schedule to be Announced...</h1>
                             </Paper>
                          ):(

                            <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                            <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Counselling Schedule</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>

                            <TableBody>                                  
                                 <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>Year</b></StyledTableCell> 
                                    <StyledTableCell align="centre"><b>Round</b></StyledTableCell> 
                                    <StyledTableCell align="centre"><b>Entrance Test</b></StyledTableCell>                            
                                    <StyledTableCell align="centre"><b>Start Counselling</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>End Counselling</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>AllotmentDate</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Document Verification Start</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Document Verification End</b></StyledTableCell>                                    
                                    </StyledTableRow>
                                                                      
                                        {this.state.counsellingDetail.map((fullDetail,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{fullDetail.year}</StyledTableCell>
                                        <StyledTableCell align="centre">{fullDetail.counsellingCount}</StyledTableCell>                                                   
                                        <StyledTableCell align="centre"> {fullDetail.EntranceName}</StyledTableCell>
                                        <StyledTableCell align="centre">  {fullDetail.counsellingStartDate} </StyledTableCell>
                                        <StyledTableCell align="centre">  {fullDetail.counsellingEndDate} </StyledTableCell>
                                        <StyledTableCell align="centre">  {fullDetail.processDateSchedule?fullDetail.processDateSchedule:'Not Announced'} </StyledTableCell>
                                        <StyledTableCell align="centre">  {fullDetail.counsellingDocVerificationStartDate?fullDetail.counsellingDocVerificationStartDate :'Not Announced'} </StyledTableCell>
                                        <StyledTableCell align="centre">  {fullDetail.counsellingDocVerificationEndDate?fullDetail.counsellingDocVerificationEndDate:'Not Announced'} </StyledTableCell>
                                                                            
                                          </StyledTableRow>
                                          ))}                                     
                                     </TableBody>

                                </Table>
                                </TableContainer>

                            </Paper>


                             
)}
                            </DialogContentText>
                          </DialogContent>
                         
                          <DialogActions>
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                             {/* <Fab variant="extended" color="primary" size="medium" onClick={this.handleNoHelp} style={{width: '100%'}}>
                            
                            close
                        </Fab> */}
                            <Button onClick={this.handleNoHelp} color="primary">
                              Close
                            </Button>
                          </DialogActions>
                  </Dialog>
        </div>
    );

}
  

}