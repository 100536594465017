//This page defines the URL for each redirected page.
// e.g: 1) student will have localhost:3000/student/hbfhubijknjkn
//      2) registrar will be like /registrar/xxxxxxxxxxxx

import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import StudentDashboard from './StudentDashboard';
import PrivateRoute from '../routes/PrivateRoute';
import PublicRoute from '../routes/PublicRoute';
//import ChooseCourse from './ChooseCourse';
import NoMatch from './NoMatch';
import '../styles/App.css';
import OTPVerification from './OTPVerification';
import SetPassword from './SetPassword';
import LoginAndRegister from './LoginAndRegister';
import AdminLogin from '../PanelView/Login';
import RegistrarDashboard from '../PanelView/RegistrarDashboard';
import ChairpersonDashboard from '../PanelView/ChairpersonDashBoard';
import AddEmail from './AddEmail';


function App() {
  return (
    <BrowserRouter>
        <Switch>
          <PublicRoute restricted={true} component={LoginAndRegister} path="/" exact />
          <PublicRoute component={OTPVerification} path="/verifyOTP" exact />
          <PublicRoute component={AddEmail} path="/addemail" exact />
          <PublicRoute component={SetPassword} path="/setpassword" exact />
          <PrivateRoute component={StudentDashboard} path="/student/:token" exact />
          <PublicRoute component={AdminLogin} path="/adminlogin" exact />
          
          <PrivateRoute component={RegistrarDashboard} path="/registrar/:token" exact />
          <PrivateRoute component={ChairpersonDashboard} path="/chairperson/:token" exact />
          

          {/* <PublicRoute component={StudentDashboard} path="/student" exact /> */}
          {/* <PrivateRoute component={RegistrarDashboard} path="/registrar/:token" exact />
          <PrivateRoute component={ChairpersonDashboard} path="/chairperson/:token" exact />
          
          <PrivateRoute component={ChooseCourse} path="/choosecourse/:token" exact /> */}
          <PublicRoute component={NoMatch} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
