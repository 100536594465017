import React from 'react';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import {ProtectDOB} from '../components/ProtectDOB';
import PAUCounsellingDetail from '../methods/PAUCounsellingDetail';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Select from '@material-ui/core/Select';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import ViewProcessedResult from '../methods/ViewProcessedResult';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import AddIcon from '@material-ui/icons/Add';
import FinalAllotmentUpdateStatus from '../methods/FinalAllotmentUpdateStatus';
import ViewProcessedSeatStaus from '../methods/ViewProcessedSeatStaus';
import ViewProcessedStudentProgramPriority from '../methods/ViewProcessedStudentProgramPriority';
import ViewProcessedCategoryPriority from '../methods/ViewProcessedCategoryPriority';
import ViewCompleteData from '../methods/ViewCompleteData';
import ViewCompleteSeatData from '../methods/ViewCompleteSeatData';
import DisapproveFinalAllotment from '../methods/DisapproveFinalAllotment';
import AllotmentCourseList from '../methods/AllotmentCourseList';
import AllotmentCategoryList from '../methods/AllotmentCategoryList';
import ViewCategoryWiseResult from '../methods/ViewCategoryWiseResult';
//import IconButton from '@material-ui/core/IconButton';

//import BlinkLable from './BlinkLable';
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });
  

export default class ProcessAllocationDialog extends React.Component{
    state={
        setOpenHelp:false,
        processedResult:[],
        processedSeatStatus:[],
        statusMessage:'',
        programPriority:[],
        categoryPriority:[],
        completeData:[],
        completeSeatData:[],
        disableApprove:'',
        setProgress:true,
        value:0,
        courseList:[],
        categoryList:[],
        selectedCategory:'',
        selectedCategoryResult:[],
    }
    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false 
          
        })         
      //setOpen(false);
    }
  //   handleDisplay(){
  //     // let displayTable={}
  //      this.state.processedResult.map((item,index)=>(
  //        console.log(item)
  //      ))

  //      // {this.props.list.filter(function (user) { // filter first for non-friends
  //      //   return user.friend !== true // returns a new array
  //      // }).map(function (user) {  //map the new array to list items
  //      //   return <li key={user.name}>{user.name}</li> // don't forget unique key for each item
  //      // })}

  //  }

    setdialogHelp=()=>{
      console.log('set dialog')
        var year=this.props.year;
         //console.log(year1)
         var round=this.props.round;
         //console.log(round);
         var  typeId=this.props.typeId;
         //console.log(typeId);
         var entranceType=this.props.entranceType;
         //console.log(entranceType);
        this.setState({
            setOpenHelp:true
        })

          AllotmentCourseList(typeId,entranceType).then(resp=>{
            if(resp){
              if(resp.data){
                this.setState({
                  courseList:resp.data
                })               
              }            
            }  
     })
     AllotmentCategoryList().then(resp=>{
       if(resp){
         if(resp.data){
           this.setState({
             categoryList:resp.data
           })
         }
       }
     }) 

      




        ViewProcessedResult(year,round,typeId,entranceType).then(resp=>{
            if(resp){
                console.log(resp.data)
                if(resp.data){
                   this.setState({
                    processedResult:resp.data
                   })
                   var test=resp.data[0];
                   var statusCheck=test.approvalStatus;
                   console.log(statusCheck)

                   //Enable Disable Final Approval Button 
                   this.setState({
                     disableApprove:statusCheck
                   })
                }
                // else if(resp.data==="No"){
                //    this.setState({
                 
                //    })
                // }
                // else{
                // this.setState({
                                    
                //                 })
                // }
            }
        })
        console.log('set process result')
        ViewProcessedSeatStaus(year,round,typeId,entranceType).then(resp=>{
          if(resp){
            if(resp.data){
                 this.setState({
                  processedSeatStatus:resp.data
                 })
            }
            else{

            }
          }
        })

        ViewProcessedStudentProgramPriority(year,round,typeId,entranceType).then(resp=>{
          if(resp){
            if(resp.data){
                    this.setState({
                      programPriority:resp.data
                    })
            }
            else{

            }
          }
        })

        ViewProcessedCategoryPriority(year,round,typeId,entranceType).then(resp=>{
          if(resp){
            if(resp.data){
              this.setState({
                categoryPriority:resp.data
              })
            }
          }
        });

        ViewCompleteData(year,round,typeId,entranceType).then(resp=>{
          if(resp){
            if(resp.data){
              this.setState({
                completeData:resp.data,
                setProgress:false
              })
            }
            console.log(this.state.completeData);
          }
        });

        ViewCompleteSeatData(year,round,typeId,entranceType).then(resp=>{
          if(resp){
            if(resp.data){
              this.setState({
                completeSeatData:resp.data,
                //setProgress:false
              })
            }
            console.log(this.state.completeSeatData);
          }
        });
          
       // this.handleDisplay();
        //console.log('set process result2')
   
    }
   



    handleChange = (event, newValue) => {
      // called on change of TABS
        // if(newValue === 0){
        //   this.setState({
        //     tabColor: "secondary"
        //   })
        // } else if(newValue === 1){
        //   this.setState({
        //     tabColor: "primary"
        //   })
        // }
     //   setValue(newValue);
        this.setState({
          value: newValue
        })
      };
      disapproveAllotment=()=>{
        this.setState({
          setProgress:true
        })
        var year=this.props.year;
         //console.log(year1)
         var round=this.props.round;
         //console.log(round);
         var  typeId=this.props.typeId;
         //console.log(typeId);
         var entranceType=this.props.entranceType;
         DisapproveFinalAllotment(year,round,typeId,entranceType).then(resp=>{
          if(resp){
            if(resp.data==="Yes"){
                this.setState({
                   statusMessage:'Previous Allotment Cancelled Successfully',
                   disableApprove:0,
                   setProgress:false,

                })
            }
            else if(resp.data==="No"){
                this.setState({
                   //statusMessage:'Error'
                })
            }
        }
          })

      }

      handleViewCategoryWise=()=>{
        var year=this.props.year;
        //console.log(year1)
        var round=this.props.round;
        //console.log(round);
        var  typeId=this.props.typeId;
        //console.log(typeId);
        var entranceType=this.props.entranceType;
        //console.log(entranceType);
        const {selectedCategory}=this.state
        ViewCategoryWiseResult(year,round,typeId,entranceType,selectedCategory).then(resp=>{
          if(resp){
            if(resp.data==='No'){
              this.setState({
                statusMessage:'No Record to Display',
                selectedCategoryResult:[]
              })
            }
            else{
              this.setState({
                selectedCategoryResult:resp.data,
                statusMessage:'',

              })
            }
          }
        })
      }
    
    finalAllotment=()=>{
       this.setState({
         setProgress:true
       })
        var year=this.props.year;
         //console.log(year1)
         var round=this.props.round;
         //console.log(round);
         var  typeId=this.props.typeId;
         //console.log(typeId);
         var entranceType=this.props.entranceType;
         //console.log(entranceType);
         
         FinalAllotmentUpdateStatus(year,round,typeId,entranceType).then(resp=>{
             if(resp){
                 if(resp.data==="Yes"){
                     this.setState({
                        statusMessage:'Final Allotment Successful',
                        disableApprove:1,
                        setProgress:false,

                     })
                 }
                 else if(resp.data==="No"){
                     this.setState({
                        //statusMessage:'Error'
                     })
                 }
             }

         })


    }
    componentDidMount(){
         //this.ViewCounsellingDetail();
        //this.ViewCourseList();
        //  console.log("Hello");
        //  var year1=this.props.year;
        //  console.log(year1)
        var  typeId=this.props.typeId;        
        var entranceType=this.props.entranceType;
        console.log(typeId)
        console.log(entranceType)
    }

    setCategory=({target:{value}})=>{
      this.setState({
      selectedCategory:value,
      });
    
       }

    // ViewCourseList=()=>{
    //      var year=this.props.year;
    //      //console.log(year1)
    //      var round=this.props.round;
    //      console.log(round);
    //      var  typeId=this.props.typeId;
    //      console.log(typeId);
    //      var entranceType=this.props.entranceType;
    //      console.log(entranceType);
    //      console.log("helllllllllllllllooooooooooooooooooooooooooooo");
    //  AllotmentCourseList(typeId,entranceType).then(resp=>{
    //         if(resp){
    //           if(resp.data=='No'){
    //             console.log(resp.data)
    //           }
    //           else{
    //             console.log(resp.data)
    //           }
    //         }  
    //  }) 
    // }



    // ViewCounsellingDetail=()=>{
    //   console.log("Hello Counselling View");
    //   PAUCounsellingDetail().then(resp=>{
    //       if(resp){
    //           if(resp.data){
    //             console.log(resp.data);
    //               this.setState({
    //                 counsellingDetail:resp.data
    //               })
    //            //console.log(resp.data)
    //           }
    //           else{
    //            console.log('Else COunselling Detail')
    //           }
    //       }
    //   })

    // }
    
render(){
   const {value}=this.state;
   //const classes = useStyles();
   console.log(this.state.selectedCategory)

    return(
        <div>
           <Fab  color="secondary" size="small"
                         style={{marginBottom:'0%',marginLeft:'10px'}}
                         onClick={this.setdialogHelp}
                           >
                           <ArrowForwardIosIcon/> 
                          </Fab>  
                          
            {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
             onClick={this.setdialogHelp}>
            View
            </Fab> */}

            {/* <Button onClick={this.setdialogHelp}>
             <BlinkLable label='Click Here to View Counselling Schedule'> </BlinkLable>   
            </Button> */}

             {/* <IconButton title='HELP' onClick={this.setdialogHelp} style={{color:'white'}}>
                          <HelpTwoToneIcon fontSize='large'/>
                          </IconButton> */}
                          
                    

            
              <Dialog fullScreen
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                         
                         >
                        {/* <Dialog  fullScreen
                            // maxWidth={maxWidth}
                            open={this.state.setOpenHelp}
                            onClose={this.handleNoHelp}
                            aria-labelledby="max-width-dialog-title"
                        > */}
                          {/* <DialogTitle id="alert-dialog-title">{"Allotment Result"}</DialogTitle> */}
                          {/* <Tooltip title="Add" aria-label="add"> */}
  {/* <Fab color="primary">
    <AddIcon />
  </Fab> */}
{/* </Tooltip> */}




<Grid style={{flexGrow:1,backgroundColor:'white', paddingTop:'1%',paddingBottom:'1%',color:'#000080'}}>
{this.state.statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{this.state.statusMessage}</h5></Box>} 
    <Typography   
    > <span style={{fontSize:'2.2em',paddingLeft:'3%'}}><b>PAU Online Counselling Provisonal Result</b></span></Typography>
    </Grid>

<Paper>
{this.state.setProgress===true?(
<LinearProgress color="secondary"
                           disabled={true}
                            />
                            ):(<div></div>)}
    </Paper>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                              <Paper>
                                <br/>
                                <br/>
                                <Grid>

                                  
                             
                            <Tabs
                              value={value}
                              onChange={this.handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                             // scrollButtons='auto'
                              centered
                            >
{/* finall tab count and order---------------- */}
<Tab label="Overall Result"  />
<Tab label="Seat Matrix" />   
        <Tab label="Full Allotment Result" />
        <Tab label="Course Preferences" />
        <Tab label="Category Preferences" />
        <Tab label="Category Allotment Result" />
    

        {/* ----------------------------------------------- */}

{/* <Tab label="Overall Result"  />
         <Tab label="Vacant Seats" />
        <Tab label="Full Allotment Result" />
        <Tab label="Course Preferences" />
        <Tab label="Category Preferences" />
        <Tab label="Category Allotment Result" />
        <Tab label="Seat Matrix" /> */}

         {/* <Tab label="Comprehensive Result" />
         <Tab label="Category-wise Vacant Seats" />
        <Tab label=" Complete Allotment Result" />
        <Tab label="Course Preferences" />
        <Tab label="Category Preferences" />
        <Tab label="Category-wise Allotment Result" />
        <Tab label="Course-Wise Seat Matrix" /> */}

        {/* <Tab label="Course-Category wise List" /> */}
       
        
      
        
      </Tabs>
      </Grid>
      
        <TabPanel value={this.state.value} index={11}>
        {/* <h1>{this.state.value}</h1> */}
                            <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Category-wise Vacant Seats</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer>                            
                                <MaterialTable
                                    title="List of Category-wise Vacant Seats"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                  {title:'S.No',field:'sno'},  
                                    {title:'Exam',field:'exam'},
                                    {title:'Program',field:'pro'},
                                    {title:'Category',field:'cat'},
                                    {title:'Total Seats',field:'tseat'},
                                    {title:'Filled Seats',field:'fseat'},
                                    {title:'Vacant Seats', field:'vseat'},
                                    
                                ]}
                                data={                               
                                    this.state.processedSeatStatus.map((resultSeatInfo,index)=>(
                                        {         
                                          'sno': index+1,                        
                                          'exam':resultSeatInfo.EntranceName,
                                          'pro':resultSeatInfo.program,
                                          'cat': resultSeatInfo.category,
                                          'tseat':resultSeatInfo.categoryMaxSeat,
                                          'fseat':resultSeatInfo.categoryFilledSeat,
                                          'vseat':resultSeatInfo.categoryVacantSeat,                                                          
                                        }  
                                    ))
                                }  
                                      options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.processedSeatStatus.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}                                 
                                />

                           <br/><br/>
                           <br/><br/>
                           </TabPanel>

                           {/* ********************************************************************** */}
                           <TabPanel value={this.state.value} index={3}>
        {/* <h1>{this.state.value}</h1> */}
                           <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Student Course Preferences</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer> 
                                <MaterialTable
                                    title="List of Student Course Preferences"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                    //{title:'Exam',field:'exam'},
                                    //{title:'Year',field:'year'},
                                    //{title:'Round',field:'round'},
                                    {title:'S.No',field:'sno'},
                                    {title:'Roll No',field:'reg'},
                                    {title:'Name',field:'name'},                                    
                                    {title:'Course Name',field:'program'},
                                    {title:'Priority No.',field:'priority'},
                                  
                                    // {title:'Total Seats',field:'tseat'},
                                    // {title:'Filled Seats',field:'fseat'},
                                    // {title:'Vacant Seats', field:'vseat'},
                                    
                                ]}
                                data={                               
                                    this.state.programPriority.map((resultProgramPriorityInfo,index)=>(
                                        {                    
                                        'sno': index+1,                
                                          'reg':resultProgramPriorityInfo.registrationNo,
                                          'name':resultProgramPriorityInfo.name,
                                          'program': resultProgramPriorityInfo.program,
                                          'priority':resultProgramPriorityInfo.programPriorityCount,                                                                                                  
                                        }  
                                    ))
                                }  
                                      options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.programPriority.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}                                 
                                />
                                </TabPanel>
                                {/* ***************************************************************************** */}
                                <TabPanel value={this.state.value} index={4}>
  
                           <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Student Category Preferences</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer>  
                                <MaterialTable
                                    title="List of Student Category Priority"                              
                                columns  ={[      
                                  {title:'S.No',field:'sno'},                              
                                    {title:'Roll No',field:'reg'},
                                    {title:'Name',field:'name'},                                    
                                    {title:'Category',field:'category'},
                                    {title:'Priority No.',field:'priority'},                               
                                ]}
                                data={                               
                                    this.state.categoryPriority.map((resultCategoryPriorityInfo,index)=>(
                                        {                 
                                          
                                          'sno': index+1,                   
                                          'reg':resultCategoryPriorityInfo.registrationNo,
                                          'name':resultCategoryPriorityInfo.name,
                                          'category': resultCategoryPriorityInfo.category,
                                          'priority':resultCategoryPriorityInfo.categoryPriorityCount,                                                                                                  
                                        }  
                                    ))
                                }
                                      options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.categoryPriority.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}                                   
                                />
                           <br/><br/>
                           <br/><br/> 
                           </TabPanel>
                           {/* ************************************************************************************* */}

                           <TabPanel value={this.state.value} index={5}>
                           <Grid style={{padding:'1%',width:'100%'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3}>
                 <Grid  container   direction="row" >
                 {/* <Grid item style={{width:'45%',paddingLeft:'5%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Course</InputLabel>
                            <Select>
                           <option>   Select Course</option>                            
                            {this.state.courseList.map((course,id)=>(
                              <option key={id} value={course.program_id}>
                                 {id+1} . {course.program}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid> */}

                      <Grid item style={{width:'50%',paddingLeft:'15%',paddingRight:'5%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Category</InputLabel>
                            <Select onChange={this.setCategory}  value={this.state.selectedCategory}>
                           <option>   Select Category</option>                            
                            {this.state. categoryList.map((cat,id)=>(
                              <option key={id} value={cat.category_id}>
                                 {id+1} . {cat.category}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                      <Grid item style={{width:'20%',paddingLeft:'5%',paddingRight:'2%'}}>
                      <Fab  color="secondary" size="small"

                          onClick={this.handleViewCategoryWise}
                         style={{marginBottom:'0%',marginLeft:'0px'}}
                           >
                           <ArrowForwardIosIcon/> 
                          </Fab> 
                      </Grid>

                 </Grid>
                 </Box>
                 </Paper>
                 <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Category Wise Result</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer>

                                <MaterialTable
                                    title="Category Wise Result"                               
                                columns  ={[
                                  {title:'S.No',field:'sno'},
                                    {title:'Roll No',field:'reg'},
                                    {title:'UID/Prospectus No.',field:'uid'},
                                    {title:'Name',field:'name'},
                                    {title:'Course Allocation', field:'allocatedProgram'},
                                    {title:'Category Allocation',field:'allocatedCat'},                                    
                                ]}
                                data={                               
                                    this.state.selectedCategoryResult.map((categoryWise,index)=>(
                                        {                
                                         
                                          'sno': index+1,                    
                                          'reg':categoryWise.registrationNo,
                                          'uid':categoryWise.uidPro,
                                          'name':categoryWise.name,
                                          'allocatedProgram':categoryWise.program,
                                          'allocatedCat':categoryWise.category,                                                                         
                                        }  
                                    ))
                                }
                                      options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.selectedCategoryResult.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}    
                                
                                />
        </Grid>

                           </TabPanel>
                           {/************************************************************ */}
                           <TabPanel value={this.state.value} index={2}>
        {/* <h1>{this.state.value}</h1> */}
        

                            <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Allotment Result</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer>

                                <MaterialTable
                                    title="Allotment Result"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                  {title:'S.No',field:'sno'},
                                    {title:'Roll No',field:'reg'},
                                    {title:'UID/Prospectus No.',field:'uid'},
                                    {title:'Name',field:'name'},
                                    {title:'Provisional Course Allocation', field:'allocatedProgram'},
                                    {title:'Provisional Category Allocation',field:'allocatedCat'},
                                    // {title:'Mother Name',field:'mName'},
                                    // {title:'Father Name',field:'fName'},
                                    // {title:'Action',field:'action'},
                                    // {title:'Process Date',field:'processDate'},
                                    // {title:'Verification Start',field:'verificationStart'},
                                    // {title:'Verification End',field:'verificationEnd'},
                                    // {title:'Action',field:'action'},
                                ]}
                                data={                               
                                    this.state.processedResult.map((resultInfo,index)=>(
                                        {                              
                                        
                                          'sno': index+1,      
                                          'reg':resultInfo.registrationNo,
                                          'uid':resultInfo.uidPro,
                                          'name': resultInfo.name,
                                          'allocatedProgram':resultInfo.program,
                                          'allocatedCat':resultInfo.category,
                                          //'action': <ViewAppliedFullDialog                                                   
                                          //appliedCounsellingId={info.stdAppliedCoun_id}/>
                                          // 'action':counsellingDetail.counsellingEndDate,
                                          // 'processDate':counsellingDetail.processDateSchedule,
                                          // 'verificationStart':counsellingDetail.counsellingDocVerificationStartDate,
                                          // 'verificationEnd':counsellingDetail.counsellingDocVerificationEndDate,                 
                                                                          
                                        }  
                                    ))
                                }
                                      options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.processedResult.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}    
                                // data={this.state.counsellingSchedule}
                                // data={data}
                                />
                                </TabPanel>
                                {/* ************************************************************************* */}
                                <TabPanel value={this.state.value} index={0}>
                                <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Comprehensive Result</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer>

                                <MaterialTable
                                    title="Comprehensive Result"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                  {title:'S.No',field:'sno'},
                                    {title:'Roll No',field:'reg'},
                                    {title:'Rank',field:'rank'},
                                    {title:'Name',field:'name'},
                                  //  {title:'C1',field:'c1'},
                                    {title:'Qualification Marks',field:'marks'},
                                    {title:'Date of Birth',field:'dob'},
                                    {title:'Application Status',field:'status'},
                                    {title:'Course Allotment',field:'allocateProgram'},
                                    {title:'Category Allotment',field:'allocateCategory'},
                                    {title:'AF',field:'af1'},
                                    {title:'BC',field:'bc2'},
                                    {title:'DP',field:'dp3'},
                                    {title:'FF',field:'ff4'},
                                    {title:'GEN',field:'gen5'},
                                    {title:'KM',field:'km6'},
                                    {title:'PAUO',field:'pauo7'},
                                    {title:'SC',field:'sc8'},
                                    {title:'SP',field:'sp9'},
                                    {title:'TA',field:'ta10'},
                                    {title:'TSA',field:'tsa11'},
                                    {title:'PAUC',field:'pauc12'},
                                    {title:'KAO',field:'kao13'},
                                    {title:'B.Sc(Hons) Agriculture',field:'BSCAgri1'},
                                    {title:'B.Sc(Hons) Horticulture',field:'BSCHorti2'},
                                    {title:'B.Tech Biotechnology',field:'BioTech3'},
                                    {title:'B.Tech Food Technology',field:'foodTech4'},
                                    {title:'B.Sc.(Hons) Community Science',field:'communitySci5'},
                                    {title:'B.Sc.(Hons) Nutrition & Dietetics',field:'n&d6'},
                                    {title:'M.Sc Biochemistry',field:'biochemi7'},
                                    {title:'M.Sc Botony',field:'botony8'},
                                    {title:'M.Sc Chemistry',field:'chemi9'},
                                    {title:'M.Sc Micobiology',field:'micro10'},
                                    {title:'M.Sc Zoology',field:'zoology11'},
                                    

                                    
                                   // {title:'C1',field:'c13'},
                                  
                                ]}
                                data={                               
                                    this.state.completeData.map((resultInfo,index)=>(
                                        {                                 
                                          // 'reg':resultInfo,
                                        
                                          'sno': index+1,   
                                          'reg':resultInfo[0],
                                          'rank':resultInfo[1],
                                          'name':resultInfo[2],
                                          //'c1':resultInfo[2]?resultInfo[2]:'--',
                                          'marks':resultInfo[3]?resultInfo[3]:'--',
                                          'dob':resultInfo[4]?ProtectDOB(resultInfo[4]):'--',
                                          'status':resultInfo[5],
                                          'allocateProgram':resultInfo[6]?resultInfo[6]:'--',
                                          'allocateCategory':resultInfo[7]?resultInfo[7]:'--',
                                          
                                          'af1':resultInfo[8]?resultInfo[8]:'--',
                                          'bc2':resultInfo[9]?resultInfo[9]:'--',
                                          'dp3':resultInfo[10]?resultInfo[10]:'--',
                                          'ff4':resultInfo[11]?resultInfo[11]:'--',
                                          'gen5':resultInfo[12]?resultInfo[12]:'--',
                                          'km6':resultInfo[13]?resultInfo[13]:'--',
                                          'pauo7':resultInfo[14]?resultInfo[14]:'--',
                                          'sc8':resultInfo[15]?resultInfo[15]:'--',
                                          'sp9':resultInfo[16]?resultInfo[16]:'--',
                                          'ta10':resultInfo[17]?resultInfo[17]:'--',
                                          'tsa11':resultInfo[18]?resultInfo[18]:'--',
                                          'pauc12':resultInfo[19]?resultInfo[19]:'--',
                                          'kao13':resultInfo[20]?resultInfo[20]:'--',

                                          'BSCAgri1':resultInfo[21]?resultInfo[21]:'--',
                                          'BSCHorti2':resultInfo[22]?resultInfo[22]:'--',
                                          'BioTech3':resultInfo[23]?resultInfo[23]:'--',
                                          'foodTech4':resultInfo[24]?resultInfo[24]:'--',
                                          'communitySci5':resultInfo[25]?resultInfo[25]:'--',
                                          'n&d6':resultInfo[26]?resultInfo[26]:'--',
                                          'biochemi7':resultInfo[27]?resultInfo[27]:'--',
                                          'botony8':resultInfo[28]?resultInfo[28]:'--',
                                          'chemi9':resultInfo[29]?resultInfo[29]:'--',
                                          'micro10':resultInfo[30]?resultInfo[30]:'--',
                                          'zoology11':resultInfo[31]?resultInfo[31]:'--',
                                                   
                                                                          
                                        }  
                                    ))
                                }
                                options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.completeData.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}   

                                />
                         
                              </TabPanel>



                               {/* ************************************************************************* */}
                               <TabPanel value={this.state.value} index={1}>
                                <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                            <Table style={{width:'100%'}} aria-label="customized table">
                            <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='8'>Course-wise Seat Status</StyledTableCell>                                                                      
                                   </TableRow>
                            </TableHead>
                            <TableBody>   </TableBody>
                                </Table>
                                </TableContainer>

                                <MaterialTable
                                    title="Course-wise Seat Matrix"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                    // {title:'Roll No',field:'reg'},
                                  //   {title:'Rank',field:'rank'},
                                  //   {title:'Name',field:'name'},
                                  // //  {title:'C1',field:'c1'},
                                  //   {title:'Qualification Marks',field:'marks'},
                                  //   {title:'Date of Birth',field:'dob'},
                                  //   {title:'Application Status',field:'status'},
                                  //   {title:'Course Allotment',field:'allocateProgram'},
                                  //   {title:'Category Allotment',field:'allocateCategory'},
                                  {title:'S.No',field:'sno'},
                                  {title:'Course Name',field:'course'},
                                  {title:'Type',field:'type'},
                                  {title:'AF',field:'af1'},
                                    {title:'BC',field:'bc2'},
                                    {title:'DP',field:'dp3'},
                                    {title:'FF',field:'ff4'},
                                    {title:'GEN',field:'gen5'},
                                    {title:'KM',field:'km6'},
                                    {title:'PAUC',field:'pauc7'},
                                    {title:'SC',field:'sc8'},
                                    {title:'SP',field:'sp9'},
                                    {title:'TA',field:'ta10'},
                                    {title:'TSA',field:'tsa11'},
                                    {title:'PAUO',field:'pauo12'},
                                    {title:'KAO',field:'kao13'},
                               

                                    
                                   // {title:'C1',field:'c13'},
                                  
                                ]}
                                data={                               
                                    this.state.completeSeatData.map((resultInfo,index)=>(
                                        {                                 
                                          // 'reg':resultInfo,
                                          // 'reg':resultInfo[0],
                                          // 'rank':resultInfo[1],
                                          // 'name':resultInfo[2],
                                          // //'c1':resultInfo[2]?resultInfo[2]:'--',
                                          // 'marks':resultInfo[3]?resultInfo[3]:'--',
                                          // 'dob':resultInfo[4]?ProtectDOB(resultInfo[4]):'--',
                                          // 'status':resultInfo[5],
                                          // 'allocateProgram':resultInfo[6]?resultInfo[6]:'--',
                                          // 'allocateCategory':resultInfo[7]?resultInfo[7]:'--',
                                          'sno': index+1,
                                          'course': resultInfo[0],
                                          'type':resultInfo[1],
                                           'af1':resultInfo[2],
                                          'bc2':resultInfo[3],
                                           'dp3':resultInfo[4],
                                           'ff4':resultInfo[5],
                                          'gen5':resultInfo[6],
                                           'km6':resultInfo[7],
                                           'pauc7':resultInfo[8],
                                         'sc8':resultInfo[9],
                                         'sp9':resultInfo[10],
                                           'ta10':resultInfo[11],
                                           'tsa11':resultInfo[12],
                                          'pauo12':resultInfo[13],
                                           'kao13':resultInfo[14],

                                          
                                                   
                                                                          
                                        }  
                                    ))
                                }
                                options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.completeSeatData.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}   

                                />
                         
                              </TabPanel>
                                </Paper>
                            </Paper>


                             
         
                            </DialogContentText>
                          </DialogContent>

                          <DialogActions>

<Grid  container   direction="row" style={{marginLeft:'35%'}}>

<Grid style={{paddingRight:'1%',paddingLeft:'0%',width:'20%'}}>

                                    <Fab variant="extended" color="secondary" size="medium" 
                                    onClick={this.finalAllotment}
                                    disabled={this.state.disableApprove===1} 
                                     style={{marginLeft:'1%',marginRight:'1%'}}>
                                        Approve
                                        </Fab>
                                                             
</Grid>
<Grid style={{paddingRight:'1%',paddingLeft:'0%',width:'20%'}}>

                                    <Fab variant="extended" color="secondary" size="medium" 
                                    onClick={this.disapproveAllotment}
                                    disabled={this.state.disableApprove===0} 
                                     style={{marginLeft:'1%',marginRight:'1%'}}>
                                        Disapprove
                                        </Fab>
                                                             
</Grid>

<Grid style={{paddingRight:'0%',paddingLeft:'0%',width:'20%'}}>                      
<Fab variant="extended" color="primary" size="medium" 
                                        onClick={this.handleNoHelp}
                                       style={{marginLeft:'2%',marginRight:'2%'}}      
                                               >
                                        Close
                                        </Fab>

</Grid> 
 
 </Grid>
</DialogActions>



                          {/* <DialogActions>
                            
                              <Grid container style={{flexGrow:1,flexDirection:'row', paddingTop:'0%',paddingBottom:'1%', paddingLeft:'75%',allignItems:'center'}}>
                                 
                                    <Fab variant="extended" color="secondary" size="medium" 
                                    onClick={this.finalAllotment}
                                    disabled={this.state.disableApprove===1} 
                                     style={{marginLeft:'1%',marginRight:'1%'}}>
                                        Final Allotment 
                                        </Fab>
                                  
                                    <Fab variant="extended" color="primary" size="medium" 
                                        onClick={this.handleNoHelp}
                                       style={{marginLeft:'2%',marginRight:'2%'}}      
                                               >
                                        Close
                                        </Fab>
                                      
                              </Grid>
                              </DialogActions> */}
                          {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '50%', alignItems:'centre'}}             >
                            Final Allotment 
                            </Fab> */}
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                             {/* <Fab variant="extended" color="primary" size="medium" 
                             onClick={this.handleNoHelp}
                             style={{width: '50%', alignItems:'centre'}}             >
                            Close
                            </Fab> */}

                            {/* <Button onClick={this.handleNoHelp} color="primary">
                              Close
                            </Button> */}
                        
                  </Dialog>
        </div>
    );

}
  

}