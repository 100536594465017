import Axios from "axios";

export default function SaveStudentDetail(std_id,domicile, percentage,board){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'SaveStudentDetail',
            std_id, 
            domicile,
             percentage,
             board
            }
    });
    return response;
}