import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';





export default class PAUHelpIcon extends React.Component{
    state={
        setOpenHelp:false
    }
    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false 
          
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
    }
render(){
    // const classes = useStyles;
//console.log('aaina')
    return(
        <div>
             <IconButton title='HELP' onClick={this.setdialogHelp} style={{color:'white'}}>
                          <HelpTwoToneIcon fontSize='large'/>
                          </IconButton>
              <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                         
                        >
                          <DialogTitle id="alert-dialog-title">{"PAU ONLINE COUNSELLING SYSTEM HELP"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Online Counselling System is a Punjab Agricultural University Portal to enable students to set Course Prefernces Online. It comprises of two primary parts: <em>Student Registation and Student login.</em><br/>
                                <br/><b>Steps for Student Registration:</b>
                            <br/> 1) Click On Registration.
                            <br/>2) Enter UID/Prospectus Number.
                            <br/>3) Enter Date of birth.
                            <br/>4) CLick on Register to proceed.
                            <br/>5) You will recieve and OTP on mobile/or email Id. Fill the OTP in the provided space.
                            <br/>6) Set a New password.
                            <br/>7) Student is SUCESSFULLY REGISTERED.
                            <br/><br/>
                            <b>Steps to Login for setting Course Prefrence:</b>
                            <br/>1) On Successful Registration, kindly login with your email ID  and Password to proceed.
                           <br/> 2) Select your Domicile. Only the students belonging to PUNJAB/Chandigarh are eligible.
                           <br/> 3) Select Course Preferences. The student can use arrows or drag-and-drop feature to set the course order/reorder.
                           <br/> 4) Select Category Preference.
                           <br/> 5) Preview the your applictaion wisely because the form once submitted cannot be altered.
                           <br/> 6) Click on Submit to proceed with the final form submission.
                           <br/> 7) A dialog box will aprear. Click on onClick
                           <br/> 8) The student will reviece an OTP on Mobile/email. Kindly fill the OTP at the provided space.
                           <br/> 9) Click on FINAL SUBMIT to finally submit the form.
                           <br/> 10) Download the Proirity Selection Certificate for Future.

         
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            <Button onClick={this.handleNoHelp} color="primary">
                              Close
                            </Button>
                          </DialogActions>
                  </Dialog>

        </div>
    );

}
  

}